import React, { useState } from "react";

import Carousel from "react-elastic-carousel";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Mui
import { withStyles, useTheme } from "@mui/styles";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EventIcon from "@mui/icons-material/Event";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Dialog,
  IconButton,
  Grid,
  Typography,
  Card,
  Button,
  useMediaQuery,
  Tooltip,
} from "@mui/material";

// App
import PageTemplate, { MaxWidthContainer } from "components/pageTemplate";
import theme from "app/theme";

const MasterSponsorImg = styled.img`
  height: ${(props) => props.height.sm};
  padding: 10px 25px;
  ${theme.breakpoints.up("md")} {
    height: ${(props) => props.height.md};
    padding: 20px 30px 15px;
  }
  ${theme.breakpoints.up("lg")} {
    height: ${(props) => props.height.lg};
    padding: 14px 30px;
  }
`;

const SponsorImg = styled.img`
  width: 100%;
  padding: 0px 8px;
  margin: -9px 0px;
  ${theme.breakpoints.up("sm")} {
    max-width: 240px;
    padding: 0px 15px;
    margin: -16px 0px;
  }
  ${theme.breakpoints.up("md")} {
    padding: 0px 14px;
  }
`;

const PastSponsorImg = styled.img`
  width: 100%;
  padding: 0px 8px;
  margin: -4px 0px;
  ${theme.breakpoints.up("sm")} {
    max-width: 190px;
    padding: 0px 15px;
    margin: -6px 0px;
  }
`;

const styles = (theme) => ({
  vertPadding: {
    paddingTop: "60px !important",
    paddingBottom: "60px !important",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "70px !important",
      paddingBottom: "70px !important",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "90px !important",
      paddingBottom: "90px !important",
    },
  },
  infoBg: {
    backgroundSize: "cover",
    backgroundPositon: "50% 50%",
    backgroundImage: "url(./golfTexture.png)",
  },
  infoContainer: {
    marginTop: "0px !important",
    paddingBottom: "80px",
  },
  infoCard: {
    textAlign: "center",
    padding: "30px 26px 28px !important",
    height: "100%",
  },
  infoCardh3: {
    paddingBottom: "8px",
  },
  negMarginCardXs: {
    [theme.breakpoints.up("xs")]: {
      marginTop: "-60px !important",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "-100px !important",
    },
  },
  negMarginCardMd: {
    [theme.breakpoints.up("md")]: {
      marginTop: "-100px !important",
    },
  },
  missionBg: {
    backgroundImage: "url(./missionKids.png)",
    backgroundSize: "cover",
    backgroundPosition: "43% 20%",
    [theme.breakpoints.up("sm")]: {
      backgroundPosition: "50% 20%",
    },
  },
  missionHeaderGrid: {
    borderBottom: `3px solid ${theme.palette.blue.main}`,
    paddingBottom: "35px",
    borderRight: "none",
    [theme.breakpoints.up("sm")]: {
      paddingRight: "35px",
      borderRight: `3px solid ${theme.palette.blue.main}`,
      borderBottom: "none",
    },
  },
  missionHeader: {
    color: theme.palette.blue.main,
    textAlign: "left",
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  missionDescriptionGrid: {
    padding: "35px 0px 0px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "590px",
      padding: "6px 0px 0px 35px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "590px",
      padding: "6px 90px 0px 35px",
    },
  },
  donateContainer: {
    textAlign: "center",
  },
  arrows: {
    height: "40px",
    width: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
    cursor: "pointer",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: theme.palette.green.veryLight,
    },
  },
  pagination: {
    width: "14px",
    height: "14px",
    border: `1px solid ${theme.palette.green.main}`,
  },
  activePagination: {
    border: "none",
    backgroundColor: theme.palette.green.main,
  },
  carouselContainer: {
    paddingTop: "40px",
  },
  carouselItem: {
    width: "100%",
  },
  sponsorBg: {
    backgroundColor: "rgb(205,229,181)",
    background:
      "linear-gradient(170deg, rgba(205,229,181,1) 0%, rgba(171,197,146,1) 100%)",
  },
  testimonialsBg: {
    backgroundImage: "url(./testimonialsBg.png)",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
  },
  testimonialsContainer: {
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap !important",
    },
  },
  testimonialsHeader: {
    color: "#fff",
    textAlign: "center",
    padding: "0px 30px 30px !important",
    [theme.breakpoints.up("md")]: {
      padding: "0px !important",
      width: "360px",
      textAlign: "left",
    },
    [theme.breakpoints.up("lg")]: {
      width: "400px",
    },
  },
});

const donateItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const testimonials = [
  {
    id: 1,
    quote:
      "I've been to a lot of these tournaments and this one takes the cake. Very impressed.",
    name: "D. Norman",
  },
  {
    id: 2,
    quote:
      "You just set a new standard for these [golf] outings. Great day. Great cause. Nicely done.",
    name: "E. MacDougal",
  },
  {
    id: 3,
    quote:
      "WOW! You raised the bar way up. I had a great day and the prizes were amazing.",
    name: "G. Brown",
  },
];

const sponsors = [
  // {
  //   url: "https://askanadjuster.com/",
  //   imgName: "AskAdjuster.png",
  //   name: "Ask an Adjuster",
  // },
  // {
  //   url: "https://www.rkco-architects.com/",
  //   imgName: "RKCO.jpg",
  //   name: "Richard Kapusta & Co. Architects & Planners",
  // },
  // {
  //   url: "https://www.lacherinsurance.com/",
  //   imgName: "Lacher.jpg",
  //   name: "Lacher & Associates",
  // },
  // {
  //   url: "https://www.univest.net/",
  //   imgName: "Univest.jpg",
  //   name: "Univest Banking, Insurance, Investments",
  // },
  // { url: "https://pmcrx.com/", imgName: "PMC.png", name: "PMC Pharmacy" },
  // {
  //   url: "https://www.diverselegalsolutions.com/",
  //   imgName: "diverse_legal.jpg",
  //   name: "Diverse Legal Solutions, LLC",
  // },
  // {
  //   url: "https://www.facebook.com/AndersonExcavatingLLC/",
  //   imgName: "Anders.jpg",
  //   name: "Anders Excavating Inc",
  // },
  // {
  //   url: "https://www.google.com/url?sa=D&q=https://www.canoncapital.com/&ust=1691164080000000&usg=AOvVaw2dnzgCdzv51GTSlEHQAHer&hl=en&source=gmail",
  //   imgName: "Canon.jpg",
  //   name: "Canon Capital Management Group, LLC",
  // },
  // {
  //   url: "https://www.centralmontgomeryorthopedics.com/",
  //   imgName: "CentralMontgomeryOrthopedics.jpg",
  //   name: "Central Montgomery Orthopedics",
  // },
  // {
  //   url: "https://emoyer.com/",
  //   imgName: "Moyer.jpg",
  //   name: "Moyer Indoor / Outdoor",
  // },
  // {
  //   url: "https://govolpe.com/",
  //   imgName: "Volpe.jpg",
  //   name: "Volpe Enterprises, Inc.",
  // },
  // {
  //   url: "https://www.williamsbergeykoffel.com/",
  //   imgName: "WilliamsBerkeyKoffel.jpg",
  //   name: "Williams-Bergey-Koffel Funeral Home",
  // },
  // {
  //   url: "https://www.foxrothschild.com/",
  //   imgName: "FoxRothschild.jpg",
  //   name: "Fox Rothschild, LLP",
  // },
  // {
  //   url: "https://godshalls.com/",
  //   imgName: "Godshalls.jpg",
  //   name: "Godshall's Quality  Meats",
  // },
  // {
  //   url: "https://www.pennvalleygas.com/",
  //   imgName: "PVGas.jpg",
  //   name: "Penn Valley Gas",
  // },
  // {
  //   url: "https://gotta-smile.com/",
  //   imgName: "WWR.jpg",
  //   name: "Weaver, Reckner & Reinhart",
  // },
  // {
  //   url: "https://www.lhgwlaw.com/",
  //   imgName: "LHGW.jpg",
  //   name: "Landis, Hunsberger, Gingrich & Weik",
  // },
  // {
  //   url: "https://www.alphagraphics.com/us-pennsylvania-lansdale-us822",
  //   imgName: "AG_lansdale.jpg",
  //   name: "Alphagraphics Lansdale",
  // },
  // {
  //   url: "https://www.acehardware.com/store-details/10740?utm_source=google&utm_medium=local&utm_campaign=localmaps&utm_content=10740",
  //   imgName: "ace.jpg",
  //   name: "Ace Hardware",
  // },
  // {
  //   url: "https://michalaksservicestation.com/michalak-just-cruisin-279",
  //   imgName: "Michalaks.jpg",
  //   name: "Mikalak’s",
  // },
  // {
  //   url: "http://www.clarkeslandscaping.com",
  //   imgName: "Clarkes.jpg",
  //   name: "Clarke’s Landscaping",
  // },
  // {
  //   url: "https://www.alderferauction.com/",
  //   imgName: "AlderferAuction.jpg",
  //   name: "Alderfer Auction",
  // },
  // {
  //   url: "https://bgmfg.com/",
  //   imgName: "B&G.jpg",
  //   name: "B+G",
  // },
  // {
  //   url: "https://www.herrs.com",
  //   imgName: "Herrs.jpg",
  //   name: "Herr’s",
  // },
  // {
  //   url: "http://www.rrccpa.com",
  //   imgName: "RRC.jpg",
  //   name: "Riley, Rodzianko &amp; Clymer LLP",
  // },
  // {
  //   url: "https://swartley.com",
  //   imgName: "Swartley.jpg",
  //   name: "Swartley Brothers",
  // },
  // {
  //   url: "https://www.thrivent.com",
  //   imgName: "Thrivent.jpg",
  //   name: "Thrivent",
  // },
  // {
  //   url: "https://www.penncolor.com/",
  //   imgName: "PennColor.jpg",
  //   name: "Penn Color",
  // },
  // {
  //   url: "https://www.linkedin.com/in/lance-viola-ba0b728/",
  //   imgName: "Novacor.jpg",
  //   name: "Novacor",
  // },
  // {
  //   url: "https://www.hillwallack.com/?p=5306",
  //   imgName: "HillWallack.jpg",
  //   name: "Hill Wallack",
  // },
  // {
  //   url: "https://jbsfoodsgroup.com/",
  //   imgName: "JBS.jpg",
  //   name: "JBS",
  // },
  // {
  //   url: "https://www.northpennymca.org/",
  //   imgName: "YMCA.jpg",
  //   name: "YMCA",
  // },
  // {
  //   url: "https://www.bergeys.com/",
  //   imgName: "Bergeys.jpg",
  //   name: "Bergey's",
  // },
];

const pastSponsors = [
  // { imgName: "IHS.png", name: "Industry Health Solutions" },
  { imgName: "AskAdjuster.png", name: "Ask an Adjuster" },
  // { imgName: "Unique.png", name: "Unique Wealth" },
  // { imgName: "Hatfield.png", name: "Hatfield Quality Meats" },
  { imgName: "FiveStarClaims.jpg", name: "Five Star Claims Adjusting" },
  { imgName: "Univest.jpg", name: "Univest" },
  { imgName: "RKCO.jpg", name: "Richard Kapusta &amp; Company" },
  { imgName: "Excel.jpg", name: "Excel Communications" },
  { imgName: "HillWallack.jpg", name: "Hill Wallack, LLP" },
  { imgName: "PennColor.jpg", name: "Penn Color" },
  { imgName: "Bergeys.jpg", name: "Bergey’s" },
  { imgName: "Lacher.jpg", name: "Lacher &amp; Associates" },
  { imgName: "Altek.jpg", name: "Altek" },
  { imgName: "BroadStreetPizzeria.jpg", name: "Broad Street Italian Cuisine" },
  { imgName: "ProvidentBank.jpg", name: "Provident Bank" },
  { imgName: "KJDoors.jpg", name: "KJ Doors" },
  { imgName: "JLFreed.jpg", name: "J.L. Freed Honda" },
  { imgName: "AffiliatedLifeSolutions.jpg", name: "Affiliated Life Solutions" },
  { imgName: "H&K.jpg", name: "The H&amp;K Group" },
  { imgName: "RRC.jpg", name: "Riley, Rodzianko &amp; Clymer LLP" },
  { imgName: "Anders.jpg", name: "Anders Excavating" },
  { imgName: "B&G.jpg", name: "B+G Manufacturing" },
  { imgName: "Canon.jpg", name: "Canon Capital" },
  { imgName: "", name: "Novacor LLC" },
  {
    imgName: "CentralMontgomeryOrthopedics.jpg",
    name: "Central Montgomery Orthopedics",
  },
  { imgName: "Moyer.jpg", name: "Moyer Indoor/Outdoor" },
  { imgName: "Volpe.jpg", name: "Volpe Enterprises" },
  {
    imgName: "WilliamsBerkeyKoffel.jpg",
    name: "Williams-Bergey-Koffel Funeral Home",
  },
  { imgName: "C12.jpg", name: "C12" },
  { imgName: "Clarkes.jpg", name: "Clarke’s Landscaping" },
  { imgName: "FoxRothschild.jpg", name: "Fox Rothschild LLP" },
  { imgName: "Godshalls.jpg", name: "Godshall’s Quality Meats" },
  { imgName: "JBS.jpg", name: "JBS USA" },
  {
    imgName: "LHGW.jpg",
    name: "Landis, Hunsberger, Gringrich &amp; Weik, LLP",
  },
  { imgName: "Nelson.jpg", name: "Nelson Steel Products Inc" },
  { imgName: "PVGas.jpg", name: "Penn Valley Gas" },
  { imgName: "SynaTek.jpg", name: "SynaTek Solutions" },
  { imgName: "Ashers.jpg", name: "Asher’s Chocolate" },
];

const donors = [
  {
    type: "Local Business",
    companies: [
      "Asher’s Chocolate Co.",
      "Bolton’s Farm Market",
      "Bonefish Grill",
      "Broad Street Italian Cuisine",
      "Costco",
      "Dutch Tavern",
      "Giant Supermarkets",
      "Giovanni’s Restaurant",
      "Hatfield Meats",
      "Jesse’s BBQ",
      "JBS",
      "Laura’s Restaurant",
      "Leidy’s",
      "Margarita’s",
      "McCoole’s Red Lion Inn",
      "Outback Steakhouse",
      "PJ Whelihan's",
      "Prodigo Spa",
      "Rising Sun Inn",
      "Round Guys Brewing",
      "The Metropolitan",
      "The Pour House",
      "The Stray Dog ",
      "Stove and Tap Restaurant",
      "Yantzee Chinese Restaurant ",
      "YMCA",
      "Spruce Lake",
      "Golf Tech",
    ],
  },
  {
    type: "Local Golf Course",
    companies: [
      "Blue Bell Country Club",
      "Blue Mountain Golf Course",
      "Brookside Country Club",
      "Freddie Hill Farms",
      "Honeybrook Golf Club",
      "Indian Valley Country Club",
      "Linfield National Golf Club",
      "Mount Airy Resort Golf Club",
      "Pine Crest Country Club",
      "Skippack Golf Club",
      "Skytop Lodge Golf Course",
      "Springford Country Club",
      "Sweetwater Golf Course",
      "Talamore Country Club",
      "Worcester Golf Club",
    ],
  },
];

const photos = [
  "1 CFG Group.jpg ",
  "2 Davis Group.jpg ",
  "3 Gary Brown Group.jpg ",
  "4 IMG_8873.jpg ",
  "4 IMG_9691.jpg ",
  "5 Ken Merrrit Group.jpg ",
  "5 Lance Viola Group.jpg ",
  "6 SteveY-BrianD Group.jpg ",
  "7 Weaver Group.jpg ",
  "8 Parry Brothers and Nephew 2023.jpg ",
  "9 Ben Chu Group.jpg ",
  "10 IMG_7149.jpg ",
  "10 IMG_8874.jpg ",
  "10 IMG_9498.jpg ",
  "11 IMG_9475.jpg ",
  "11 IMG_9579.jpg ",
  "11Jack and Curtis Parry.jpg ",
  "CFG Golfers.JPEG ",
  "IMG_2416.jpg ",
  "IMG_2431.jpg ",
  "IMG_2460.jpg ",
  "IMG_2483.jpg ",
  "IMG_8862.jpg ",
  "IMG_8873.jpg ",
  "IMG_8876.jpg ",
  "IMG_8899.jpg ",
  "IMG_8901.jpg ",
  "IMG_8902.jpg ",
  "IMG_9475.jpg ",
  "IMG_9498.jpg ",
  "IMG_9501.jpg ",
  "IMG_9524.jpg ",
  "IMG_9533.jpg ",
  "IMG_9535.jpg ",
  "IMG_9546.jpg ",
  "IMG_9562.jpg ",
  "IMG_9571.jpg ",
  "IMG_9688.jpg ",
  "IMG_9692.jpg ",
  "IMG_9706.jpg",
  "IMG_9997.JPEG",
  "IMG_9998.JPEG",
  "IMG_9999.JPEG",
];

const MasterSponsorLink = ({
  src,
  alt,
  url,
  extraHeight,
  isPremier,
  height,
}) => (
  <a href={url} target="_blank" rel="noreferrer">
    <MasterSponsorImg
      src={src}
      alt={alt}
      extraHeight={extraHeight}
      isPremier={isPremier}
      height={height}
    />
  </a>
);

const SponsorItem = ({ sponsor }) => (
  <>
    {sponsor.imgName ? (
      <SponsorImg
        src={"./sponsors/all/" + sponsor.imgName}
        alt={sponsor.name}
      />
    ) : (
      <Grid
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography textAlign="center">{sponsor.name}</Typography>
      </Grid>
    )}
  </>
);

const Home = ({ classes }) => {
  const theme = useTheme();
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  const isSm = isUpSm && isDownMd;
  const isMd = isUpMd && isDownLg;
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleOpen = (index) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const carouselArrow = ({ type, onClick }) => {
    return (
      <div onClick={onClick} className={`${classes.arrows} carousel-arrow`}>
        {type === "prev" ? (
          <ChevronLeftIcon color="primary" />
        ) : (
          <ChevronRightIcon color="primary" />
        )}
      </div>
    );
  };

  const renderPagination = ({ pages, activePage, onClick }) => {
    return <div></div>;
  };

  return (
    <PageTemplate
      supportingTitle1="Charity Golf Tournament Benefitting"
      mainTitle="Love Cradle International"
      supportingTitle2={<>Orphan&apos;s Ministry</>}
    >
      <Grid className={classes.infoBg}>
        <MaxWidthContainer>
          <Grid container spacing={3} className={classes.infoContainer}>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              className={`${classes.negMarginCardXs} ${classes.negMarginCardMd}`}
            >
              <Card
                elevation={4}
                className={classes.infoCard}
                style={{ paddingLeft: "60px", paddingRight: "60px" }}
              >
                <QuestionMarkIcon fontSize="large" color="success" />
                <Typography variant="h3" className={classes.infoCardh3}>
                  WHAT
                </Typography>
                <Typography variant="h5" style={{ paddingBottom: "40px" }}>
                  Charity golf tournament
                </Typography>
                <Typography variant="h6">
                  Premium prizes for multiple contests:
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  top three scores, longest drive, closest to pin, putting
                  contest, circle of gold, and more
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Grand prize for lowest score
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Golfer appreciation gift
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Hole in one car prize
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Buffet station lunch and dinner
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <Grid
                container
                spacing={3}
                style={{ height: "calc(100% + 24px)" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={`${classes.negMarginCardMd}`}
                >
                  <Card elevation={4} className={classes.infoCard}>
                    <LocationOnIcon fontSize="large" color="success" />
                    <Typography variant="h3" className={classes.infoCardh3}>
                      WHERE
                    </Typography>
                    <Typography variant="h6">
                      Indian Valley Country Club
                    </Typography>
                    <Typography variant="h6">
                      650 Bergey Road, Telford, PA
                    </Typography>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={`${classes.negMarginCardMd}`}
                >
                  <Card elevation={4} className={classes.infoCard}>
                    <EventIcon fontSize="large" color="success" />
                    <Typography variant="h3" className={classes.infoCardh3}>
                      WHEN
                    </Typography>
                    <Typography variant="h6">
                      Monday, September 30, 2024
                    </Typography>
                    <Typography variant="h6">
                      12:30 PM shot gun start
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card elevation={4} className={classes.infoCard}>
                    <AttachMoneyIcon fontSize="large" color="success" />
                    <Typography variant="h3" className={classes.infoCardh3}>
                      COST
                    </Typography>
                    <Typography variant="h6">Golfer’s fee $210</Typography>
                    <Typography variant="h6">Sponsorships available</Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card elevation={4} className={classes.infoCard}>
                    <HowToRegIcon fontSize="large" color="success" />
                    <Typography variant="h3" className={classes.infoCardh3}>
                      HOW
                    </Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      style={{ marginTop: "18px" }}
                      component={Link}
                      to="/registration"
                    >
                      REGISTER NOW
                      <ArrowForwardIosIcon
                        fontSize="8px"
                        style={{ marginLeft: "8px" }}
                      />
                    </Button>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </Grid>

      {/* ================================ TEAM PHOTOS ================================ */}
      <Grid>
        <MaxWidthContainer className={classes.vertPadding}>
          <Grid>
            <Typography
              variant="h3"
              paragraph
              textAlign="center"
              sx={{ marginBottom: "40px" }}
            >
              THE 2023 TOURNAMENT
            </Typography>
          </Grid>

          <Grid>
            <Carousel
              itemsToShow={isSm ? 2 : isMd ? 3 : isLg ? 4 : 1}
              renderArrow={carouselArrow}
              renderPagination={renderPagination}
              itemPadding={[0, 12, 0, 12]}
            >
              {photos.map((src, index) => (
                <img
                  src={`./photos/large/${src}`}
                  alt=""
                  className={classes.carouselItem}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpen(index)}
                />
              ))}
            </Carousel>
          </Grid>

          <Dialog open={open} onClose={handleClose} maxWidth="md">
            <Grid
              sx={{
                position: "absolute",
                left: "10px",
                top: "10px",
                height: "40px",
                zIndex: 7,
              }}
            >
              <a
                href={`./photos/${photos[currentImageIndex]}`}
                class="carouselDownload"
                download
              >
                <DownloadIcon />
              </a>
            </Grid>

            <ReactCarousel
              selectedItem={currentImageIndex}
              showThumbs={false}
              onChange={(index) => setCurrentImageIndex(index)}
            >
              {photos.map((src, index) => (
                <Grid
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`./photos/large/${src}`}
                    alt={`img-${index}`}
                    key={index}
                    className="galleryDialogImg"
                  />
                </Grid>
              ))}
            </ReactCarousel>
          </Dialog>
        </MaxWidthContainer>
      </Grid>

      <Grid className={classes.missionBg}>
        <MaxWidthContainer className={classes.vertPadding}>
          <Grid container>
            <Grid item sm={6} className={classes.missionHeaderGrid}>
              <Typography variant="h2" className={classes.missionHeader}>
                OUR <br /> MISSION <br /> AND <br /> VISION
              </Typography>
            </Grid>

            <Grid item sm={6} className={classes.missionDescriptionGrid}>
              <Typography variant="h6" paragraph>
                Love Cradle USA assists orphaned, foster care, and adopted
                children along with their families in the United States and
                Ukraine. The organization offers services such as facilitating
                the adoption of Ukrainian children in the U.S., providing
                post-adoption counseling and support, offering humanitarian aid,
                spiritual guidance and aiding in the relocation of Ukrainian
                refugee children and families to the U.S. and other nations.
              </Typography>
              <Typography variant="h6" paragraph>
                To enable helpless and destitute orphans live balanced,
                disciplined, and productive lives.
              </Typography>
              <Typography variant="h6">
                To give orphans every possible advantage to grow in an
                environment that empowers them to become leaders of tomorrow.
              </Typography>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </Grid>

      <MaxWidthContainer className={classes.vertPadding}>
        <Grid className={classes.donateContainer}>
          <Typography variant="h3" paragraph>
            YOU CAN CHANGE A LIFE
          </Typography>
          <Button
            variant="contained"
            disableElevation
            endIcon={<ChevronRightIcon />}
            target="_blank"
            rel="noreferrer"
            href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=9F6DPWKQ4Q9Q2"
          >
            DONATE NOW
          </Button>
        </Grid>
        <Grid className={classes.carouselContainer}>
          <Carousel
            itemsToShow={isSm ? 2 : isMd ? 3 : isLg ? 4 : 1}
            renderArrow={carouselArrow}
            renderPagination={renderPagination}
            itemPadding={[0, 12, 0, 12]}
          >
            {donateItems.map((item, index) => (
              <img
                src={`./carousel-images/carousel${index + 1}.jpeg`}
                alt="Love Cradle"
                className={classes.carouselItem}
              />
            ))}
          </Carousel>
        </Grid>
      </MaxWidthContainer>

      <Grid className={classes.sponsorBg}>
        <MaxWidthContainer
          className={classes.vertPadding}
          style={{ textAlign: "center" }}
        >
          <Grid>
            <Typography
              display="inline"
              style={{ fontWeight: "700" }}
              variant="h5"
            >
              Not a golfer?
            </Typography>
            <Typography display="inline" variant="h5">
              &nbsp;&nbsp;Please consider being a sponsor for our event.
            </Typography>
          </Grid>
          <Button
            variant="contained"
            disableElevation
            endIcon={<ChevronRightIcon />}
            style={{ marginTop: "22px" }}
            component={Link}
            to="/sponsorships"
          >
            SPONSOR NOW
          </Button>

          <Card
            elevation={4}
            style={{
              maxWidth: "950px",
              margin: "40px auto 0px",
              padding: "40px 0px",
            }}
          >
            <Typography variant="h3" style={{ paddingBottom: "30px" }}>
              2024 SPONSORS
            </Typography>

            <Grid container spacing={6}>
              {/* --------- PREMIER --------- */}
              <Grid item xs={12}>
                <MasterSponsorLink
                  url="https://simplyhatfield.com/"
                  src="./sponsors/master/Hatfield.png"
                  alt="Hatfield Meats logo"
                  height={{ sm: "65px", md: "85px", lg: " 180px" }}
                />
                <MasterSponsorLink
                  url="https://prestotape.com/"
                  src="./sponsors/master/PrestoTape.png"
                  alt="Presto Tape logo"
                  height={{ sm: "60px", md: "80px", lg: " 160px" }}
                />
              </Grid>

              {/* --------- MASTER --------- */}
              <Grid item xs={12}>
                <MasterSponsorLink
                  url="https://uniquewealth.com/"
                  src="./sponsors/master/Unique.png"
                  alt="Unique Wealth logo"
                  height={{ sm: "50px", md: "75px", lg: " 135px" }}
                />
                <MasterSponsorLink
                  url="https://ihspts.com/"
                  src="./sponsors/master/IHS.png"
                  alt="Industry Health Solutions logo"
                  height={{ sm: "40px", md: "65px", lg: " 120px" }}
                />
              </Grid>

              {/* --------- HOLE IN ONE --------- */}
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                <MasterSponsorLink
                  url="https://askanadjuster.com/"
                  src="./sponsors/all/AskAdjuster.png"
                  alt="Ask an Adjuster"
                  height={{ sm: "50px", md: "70px", lg: " 130px" }}
                />
                <Typography
                  display={"inline-block"}
                  variant="h3"
                  padding="0px 30px"
                >
                  Curt Parry
                </Typography>
                <MasterSponsorLink
                  url="https://alderferglass.com/"
                  src="./sponsors/master/Alderfer.png"
                  alt="Alderfer Glass logo"
                  height={{ sm: "50px", md: "60px", lg: " 100px" }}
                />
                <MasterSponsorLink
                  url="https://www.linkedin.com/in/lance-viola-ba0b728/"
                  src="./sponsors/all/Novacor.jpg"
                  alt="Novacor"
                  height={{ sm: "50px", md: "70px", lg: " 160px" }}
                />
              </Grid>

              {/* --------- HOLE IN ONE --------- */}
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                <MasterSponsorLink
                  url="https://www.penncolor.com/"
                  src="./sponsors/all/PennColor.jpg"
                  alt="Penn Color"
                  height={{ sm: "50px", md: "70px", lg: " 130px" }}
                />
                <MasterSponsorLink
                  url="https://pmcrx.com/"
                  src="./sponsors/all/PMC.png"
                  alt="PMC Pharmacy"
                  height={{ sm: "50px", md: "70px", lg: " 130px" }}
                />
                <MasterSponsorLink
                  url="https://www.univest.net/"
                  src="./sponsors/all/Univest.jpg"
                  alt="Univest Banking"
                  height={{ sm: "50px", md: "70px", lg: " 130px" }}
                />
                <MasterSponsorLink
                  url="https://www.centralmontgomeryorthopedics.com/"
                  src="./sponsors/all/CentralMontgomeryOrthopedics.jpg"
                  alt="Central Montgomery Orthopedics"
                  height={{ sm: "50px", md: "70px", lg: " 130px" }}
                />
                <MasterSponsorLink
                  url="http://www.rrccpa.com"
                  src="./sponsors/all/RRC.jpg"
                  alt="Riley, Rodzianko &amp; Clymer LLP"
                  height={{ sm: "50px", md: "70px", lg: " 160px" }}
                />
                <MasterSponsorLink
                  url="https://www.hkgroup.com/"
                  src="./sponsors/all/H&K.jpg"
                  alt="The H&amp;K Group"
                  height={{ sm: "50px", md: "70px", lg: " 110px" }}
                />
              </Grid>
            </Grid>
          </Card>

          {/* 
          <Card
            elevation={4}
            style={{
              maxWidth: "950px",
              margin: "40px auto 0px",
              padding: "40px 20px",
            }}
          >
            <Typography variant="h3" style={{ paddingBottom: "30px" }}>
              2024 SPONSORS
            </Typography>
          </Card> */}

          {/* <Card
            elevation={4}
            style={{
              maxWidth: "950px",
              margin: "30px auto 0px",
              padding: "40px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ paddingBottom: "10px" }}>
                  2024 SPONSORS
                </Typography>
              </Grid>
              {sponsors.map((sponsor) => (
                <Grid item xs={6} md={4}>
                  {sponsor.url ? (
                    <a href={sponsor.url} target="_blank" rel="noreferrer">
                      <SponsorItem sponsor={sponsor} />
                    </a>
                  ) : (
                    <SponsorItem sponsor={sponsor} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Card> */}

          {/* <Card
            elevation={4}
            style={{
              maxWidth: "950px",
              margin: "30px auto 0px",
              padding: "0px 40px 40px",
            }}
          >
            <Grid container spacing={2}>
              {donors.map((donor) => (
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    paragraph
                    style={{
                      textTransform: "uppercase",
                      fontWeight: 700,
                      marginTop: "40px",
                    }}
                  >
                    {donor.type} Donors
                  </Typography>
                  {donor.companies.map((company) => (
                    <Typography
                      display="block"
                      gutterBottom
                      variant="body2"
                      color="textPrimary"
                    >
                      {company}
                    </Typography>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Card> */}

          {/* <Card
            elevation={4}
            style={{
              maxWidth: "950px",
              margin: "30px auto 0px",
              padding: "40px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ paddingBottom: "10px" }}>
                  PAST SPONSORS
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"space-around"}
              >
                <MasterSponsorLink
                  url="https://simplyhatfield.com/"
                  src="./sponsors/master/Hatfield.png"
                  alt="Hatfield Meats logo"
                />
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"space-around"}
                flexWrap={"wrap"}
              >
                <MasterSponsorLink
                  url="https://prestotape.com/"
                  src="./sponsors/master/PrestoTape.png"
                  alt="Presto Tape logo"
                />
                <MasterSponsorLink
                  url="https://uniquewealth.com/"
                  src="./sponsors/master/Unique.png"
                  alt="Unique Wealth logo"
                  extraHeight
                />
                <MasterSponsorLink
                  url="https://ihspts.com/"
                  src="./sponsors/master/IHS.png"
                  alt="Industry Health Solutions logo"
                />
                
              </Grid>
              {pastSponsors.map((sponsor) => (
                <Grid item xs={6} sm={4} md={3}>
                  {sponsor.imgName ? (
                    <PastSponsorImg
                      src={"./sponsors/all/" + sponsor.imgName}
                      alt={sponsor.name}
                    />
                  ) : (
                    <Grid
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography textAlign="center">{sponsor.name}</Typography>
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </Card> */}
        </MaxWidthContainer>
      </Grid>

      <Grid className={classes.testimonialsBg}>
        <MaxWidthContainer className={classes.vertPadding}>
          <Grid container className={classes.testimonialsContainer}>
            <Grid item>
              <Typography variant="h2" className={classes.testimonialsHeader}>
                TESTIMONIALS ABOUT PAST EVENTS
              </Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {testimonials.map((item) => (
                  <Grid item xs={12} sm={4}>
                    <Card
                      elevation={3}
                      style={{
                        position: "relative",
                        paddingTop: "48px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "18px",
                          right: "22px",
                          width: "28px",
                          height: "20px",
                          backgroundImage: `url(./quotation${item.id}.png)`,
                          backgroundSize: "contain",
                        }}
                      />
                      <Typography gutterBottom>{item.quote}</Typography>
                      <Typography color="textSecondary">{item.name}</Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </Grid>
    </PageTemplate>
  );
};

export default withStyles(styles)(Home);
